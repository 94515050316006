define([
  'modules/upx/collections/collection',
  'upx.modules/ShopModule/models/ShopFlatProduct',
],
(UpxCollection, Model) => UpxCollection.extend({
  module: 'ShopModule',
  object: 'ShopFlatProduct',
  collection_method: 'naturalSearchShopFlatProducts',
  model: Model,
}));
