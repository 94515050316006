define([
  'underscore',
  'modules/common/acls/acl',
  'modules/shop.common/components/commerceAuth',
],
(_, Acl, CommerceAuth) => Acl.extend({

  authorized() {
    if (_.isArray(this.options.alias)) {
      let auth = false;
      _.each(this.options.alias, (alias) => {
        if (CommerceAuth.hasAccessByProductAlias(alias) && !auth) {
          auth = true;
        }
      });
      return auth;
    }
    return CommerceAuth.hasAccessByProductAlias(this.options.alias);
  },
}));
