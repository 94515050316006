define([
  'jquery',
  'underscore',
  'backbone',
], (
  $, _, Backbone,
) => ({

  SECURE_PREFIX: '$SECURE$',
  SECURE_SUFFIX: '$',

  randomString(len, charSet) {
    charSet = charSet || '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    let randomString = '';
    for (let i = 0; i < len; i++) {
      const randomPoz = Math.floor(Math.random() * charSet.length);
      randomString += charSet.substring(randomPoz, randomPoz + 1);
    }
    return randomString;
  },

  generatePinCode() {
    return this.randomString(4, '0123456789');
  },

  generateQrCode() {
    return this.SECURE_PREFIX + this.randomString(32) + this.SECURE_SUFFIX;
  },

}));
